<template>
  <div class="pa-10">
    <title-page icon="ico-charging-point">
      Liste des operateurs
    </title-page>

    <v-row justify="center">
      <v-col cols="6">
        <v-list>
          <template v-for="(operator, i) in operators">
            <v-divider v-if="i > 0"  :key="'list-' + operator.id" />
            <v-list-item  :key="'list-' + operator.id" :to="{name: 'operator', params: {id: operator.id}}">
              <v-list-item-content>
                <v-list-item-title>
                  {{ operator.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ operator.id }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>

                <v-row>
                  <v-col class="align-center justify-end">

                    <v-chip class="ml-2"  color="orange" dark v-if="operator.hasSessions">
                      Sessions
                    </v-chip>
                    <v-chip class="ml-2"  color="blue darken-1" dark v-if="operator.ocpi">
                      OCPI
                    </v-chip>
                    <v-chip class="ml-2" v-if="operator.isEmp">
                      eMSP
                    </v-chip>
                    <v-chip class="ml-2"  dark v-if="operator.isCpo">
                      CPO
                    </v-chip>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>



  </div>
</template>

<script>
import OperatorRepository from "@repository/OperatorRepository";

export default {
  data() {
    return {
      operators: []
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.showLoading(true)
      OperatorRepository.search().then(operators => {
        this.operators = operators
        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.showLoading(false)
      })
    }
  }
}
</script>

<style lang="scss">

</style>